@import 'variables.scss';
@import 'mixin.scss';
@import 'animations.scss';


/*===============================================================
# Common
===============================================================*/

// * {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     font-family: $font;
// }

a {
    color: inherit;
    text-decoration: none;
}

li {
    list-style-type: none;
}

.title {
    width: 50%;
    margin: auto;

    h4 {
        font-size: 50px;
        margin: 0;
        // background: rgb(178, 155, 96);
        // background: linear-gradient(90deg, rgba(178, 155, 96, 1) 0%, rgba(244, 231, 215, 0) 100%);
        padding: 0 0 3% 15%;
        font-weight: bold;
        margin-left: -15%;
        margin-bottom: 5%;
        color: #000;
        // text-shadow: 2px 2px 1px rgba($color: $golden, $alpha: 1.0);
        position: relative;
        text-align: center;

        .shapes {
            @include centerPosition;
            top: 100%;
            left: 55%;
            // background: #000;
            width: 60%;
            height: 100%;
            display: flex;
            align-items: center;

            .line {
                width: 90%;
                height: 1px;
                // position: absolute;
                // top: 100%;
                // left: 13%;
                background: rgba($color: $golden, $alpha: 0.3);
                z-index: -1;
            }

            .circle1 {
                width: 20px;
                height: 20px;
                // position: absolute;
                // top: 90%;
                // left: 33%;
                background: $goldenGradient;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                border-radius: 50%;
            }

            .circle2 {
                width: 10px;
                height: 10px;
                margin-left: 5px;
                // position: absolute;
                // top: 90%;
                // left: 33%;
                background: $goldenGradient;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                border-radius: 50%;
            }

            .circle3 {
                width: 20px;
                height: 20px;
                margin-left: 5px;
                // position: absolute;
                // top: 90%;
                // left: 33%;
                background: $goldenGradient;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                border-radius: 50%;
            }

            .circle4 {
                width: 10px;
                height: 10px;
                margin-left: 5px;
                // position: absolute;
                // top: 90%;
                // left: 33%;
                background: $goldenGradient;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                border-radius: 50%;
            }
        }

    }
}


// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .title {
        width: 80%;
        margin-top: 10%;

        h4 {
            font-size: 25px;

            .shapes{
                width: 100%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .title {
        width: 80%;
        margin-top: 10%;

        h4 {
            font-size: 30px;
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .title {
        width: 80%;
        margin-top: 10%;

        h4 {
            font-size: 25px;
        }
    }
}

@media (min-width: 768px) and (max-width: $lg){
    .title {
        width: 80%;
        margin-top: 10%;

        h4 {
            font-size: 35px;
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {}