@import '../../../Sass/variables.scss';
@import '../../../Sass/mixin.scss';
@import '../../../Sass/animations.scss';
@import "~react-image-gallery/styles/css/image-gallery.css";


.last {
    text-align: center;

    h4 {
        margin-bottom: 2%;
        font-weight: bold;
    }

    a {
        @include button;
        width: 30%;
        margin: auto;
    }
}

.frame9 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: $skin, $alpha: 0.4);
    padding: 1% 0;
    margin-top: 5%;

    p {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .frame9-star {
        display: flex;
        margin-left: 5%;

        svg {
            width: 40px;
            height: 40px;
            fill: $golden;
        }
    }
}

.cp {

    margin-top: 10%;

    .links {
        padding: 2% 2% 0 5%;
        font-size: 30px;

        svg {
            width: 20px;
            height: 20px;
            margin: 0 1%;
        }

        a {
            &:hover {
                text-decoration: underline;
                transition: .5s all ease;
            }

            &:last-child {
                font-weight: bold;
                color: $golden;
            }
        }
    }

    .cp-slider {
        padding: 5%;
        display: flex;
        align-items: stretch;

        .cp-slider-text {
            // display: flex;
            // flex-direction: column;
            width: 45%;
            margin-left: 2%;
            position: relative;

            h3 {
                @include title;
                font-size: 35px;
                margin-bottom: 3%;
            }

            .cp-slider-text-btn {
                display: flex;
                position: absolute;
                width: 100%;
                bottom: 0;
                flex-direction: column;

                a {
                    @include button;
                    width: calc(100%);
                    // margin: 0;
                    // margin-right: 2%;
                    border-radius: 10px;
                    padding: 3% 1%;
                    font-size: 18px;
                    // border: 1px solid $brown;

                    &:hover {
                        transform: none;
                    }


                    &:first-child {
                        margin-right: 3%;
                    }
                }

            }


            p {
                font-size: 17px;
                line-height: 180%;
            }
        }

        .cp-slider-images {
            width: 55%;
            position: relative;
            // display: flex;
            cursor: zoom-in;
            cursor: -webkit-zoom-in;

            .iaMfJy {
                width: 70%;
                margin: auto;
                padding: 0 5% 5% 5%;
                background: #000;
                // border-radius: 10px;
                object-fit: contain;
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
            .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
                cursor: zoom-in;
            }

            .zoom {
                display: none;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background: #000;
                z-index: 3000;
                fill: #fff;
                // background: $brown;
                border-radius: 10px;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.6));

                .zoom-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    padding: 2% 5%;

                    p {
                        color: #fff;
                        text-transform: capitalize;
                        margin: 0;
                        font-size: 20px;
                    }
                }

                .close {
                    // position: absolute;
                    // top: 2%;
                    // right: 2%;
                    width: 30px;
                    height: 30px;
                    fill: #fff;
                    z-index: 3500;
                    cursor: pointer;
                }
            }

        }

    }

    .cp-text {
        // margin-top: 5%;
        padding: 0 5% 5% 5%;

        p,
        li {
            font-size: 18px;
            line-height: 200%;
            list-style-type: unset;
        }

        b {
            font-size: 25px;
        }

        .sizechart {
            display: flex;
            justify-content: center;

            img {
                width: calc(100% / 3);
                margin: 1%;
                display: block;
                object-fit: contain;
            }
        }


    }

    .cp-how {
        padding: 0 5% 5% 5%;

        h3 {
            @include title;
            font-size: 40px;
        }

        p {
            font-size: 18px;
            line-height: 200%;
            margin-top: 2%;
        }
    }

    .cp-order {
        h3 {
            @include title;
            font-size: 40px;
            text-align: center;
        }

        .cp-order-text {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
            background: $skin;
            margin-bottom: 5%;

            .cp-order-body {
                width: calc(100%/2.2);
                display: flex;
                align-items: center;
                padding: 5%;

                &:nth-child(1),
                &:nth-child(2) {
                    border-bottom: 2px solid $golden;
                }

                &:nth-child(1),
                &:nth-child(3) {
                    border-right: 2px solid $golden;
                }

                .cp-order-text-icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 30%;

                    svg,
                    img {
                        width: 70px;
                        height: 70px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: bold;
                        margin-top: 5%;
                    }
                }

                .cp-order-text-text {
                    width: 70%;
                    text-align: center;

                    h4 {
                        font-size: 25px;
                        font-weight: bold;
                        margin-bottom: 2%;
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }

        p {
            font-size: 18px;
            padding: 0 5% 5% 5%;
        }
    }

}


// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .frame9 {
        padding: 2% 5%;
        flex-direction: column;

        p {
            font-size: 18px;
        }

        .frame9-star {
            margin-top: 2%;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    .last {
        padding: 0 5%;

        h4 {
            font-size: 20px;
        }

        a {
            width: 50%;
        }
    }

    .cp {
        margin-top: 20%;

        .links {
            font-size: 20px;
        }

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }

                .cp-slider-text-btn {
                    position: unset;
                }
            }
        }

        .cp-text {

            p,
            li {
                font-size: 16px;
            }

            .sizechart {
                flex-direction: column;
                img {
                    width: 100%;
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) {
    .frame9 {
        padding: 2% 0;
    }

    .last {
        padding: 0 5%;

        h4 {
            font-size: 20px;
        }

        a {
            width: 50%;
        }
    }

    .cp {
        margin-top: 20%;

        .links {
            font-size: 20px;
        }

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }

                .cp-slider-text-btn {
                    position: unset;
                }
            }
        }

        .cp-text {

            p,
            li {
                font-size: 16px;
            }

           
            .sizechart {
                flex-direction: column;
                img {
                    width: 100%;
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
    .frame9 {
        padding: 2% 0;
    }

    .last {
        padding: 0 5%;

        h4 {
            font-size: 20px;
        }

        a {
            width: 50%;
        }
    }

    .cp {
        margin-top: 10%;

        .links {
            font-size: 20px;
        }

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }

                .cp-slider-text-btn {
                    position: unset;
                }
            }
        }

        .cp-text {

            p,
            li {
                font-size: 16px;
            }

            .sizechart {
                img {
                    width: calc(100% / 2);
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}