@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';


// Footer =============================
.footer {
    background: rgb(52, 44, 39);
    background: linear-gradient(355deg, rgba(52, 44, 39, 1) 0%, rgba(92, 81, 75, 1) 63%);
    padding: 5% 5% 0 5%;


    .footer-body {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .footer-left {
            width: 40%;
            margin-right: 5%;

            .footer-logo {
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                }
            }

            .footer-social {
                margin-top: 15%;

                h3 {
                    color: #fff;
                    font-size: 25px;
                    font-weight: bold;
                    margin-bottom: 5%;
                }

                .footer-social-icons {
                    margin-top: 2%;

                    a {
                        width: 70px;
                        height: 70px;
                        margin-right: 2%;

                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .link1 {
            margin-left: -10% !important;
        }

        .footer-right {
            width: 40%;
            display: flex;
            justify-content: space-around;
        }

        .footer-links {
            width: 40%;
            color: #fff;

            h3 {
                font-weight: bold;
                margin-bottom: 15%;
            }

            ul {
                padding-left: 0;

                li {
                    font-size: 18px;
                    margin-bottom: 2%;
                    color: rgba($color: #fff, $alpha: 0.7);
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
        color: #fff;
        margin-top: 5%;

        p {
            font-size: 20px;
            text-align: center;
            padding: 2% 0;
            margin: 0;
        }
    }


}

// Footer ends ========================



// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .footer {
        .footer-body {
            flex-direction: column;

            .footer-left,
            .footer-right {
                width: 100%;
            }

            .footer-left {
                .footer-logo {
                    img {
                        width: 80%;
                    }
                }

                .footer-social {
                    margin: 10% 0;

                    h3 {
                        font-size: 20px;
                    }

                    .footer-social-icons {
                        a {
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                }
            }

            .footer-links {
                width: calc(100% / 2);

                h3 {
                    font-size: 20px;
                }

                a {
                    font-size: 16px;
                }
            }
        }

        .footer-copyright {
            p {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) {
    .footer {
        .footer-body {
            flex-direction: column;

            .footer-left,
            .footer-right {
                width: 100%;
            }

            .footer-left {
                .footer-logo {
                    img {
                        width: 80%;
                    }
                }

                .footer-social {
                    margin: 10% 0;

                    // h3{
                    //     font-size: 20px;
                    // }

                    // .footer-social-icons{
                    //     a{
                    //         img{
                    //             width: 40px;
                    //             height: 40px;
                    //         }
                    //     }
                    // }
                }
            }

            .footer-links {
                width: calc(100% / 2);

                h3 {
                    margin-bottom: 5%;
                }
            }
        }

        .footer-copyright {
            p {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}