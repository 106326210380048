@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';

.last {
    text-align: center;

    h4 {
        margin-bottom: 2%;
        font-weight: bold;
    }

    a {
        @include button;
        width: 30%;
        margin: auto;
    }
}

.frame9 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: $skin, $alpha: 0.4);
    padding: 1% 0;

    p {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .frame9-star {
        display: flex;
        margin-left: 5%;

        svg {
            width: 40px;
            height: 40px;
            fill: $golden;
        }
    }
}

.customized_painting {

    margin-top: 10%;

    .cp-slider {
        padding: 5%;
        display: flex;
        align-items: stretch;

        .cp-slider-text {
            // display: flex;
            // flex-direction: column;
            width: 40%;


            h3 {
                @include title;
                font-size: 40px;
            }

            .cp-slider-text-btn {
                display: flex;

                a {
                    @include button;
                    width: calc(100% / 2);
                    // margin: 3%;
                    border-radius: 10px;
                    padding: 3% 5%;

                    &:first-child {
                        margin-right: 3%;
                    }
                }

            }


            p {
                font-size: 16px;
                line-height: 160%;
            }
        }

        .cp-slider-images {
            width: 60%;
            margin-right: 2%;
            position: relative;
            // display: flex;
            cursor: zoom-in;
            cursor: -webkit-zoom-in;

            .iaMfJy {
                width: 70%;
                margin: auto;
                padding: 0 5% 5% 5%;
                background: #000;
                // border-radius: 10px;
                object-fit: contain;
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
            .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
                cursor: zoom-in;
            }

            .zoom {
                display: none;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background: #000;
                z-index: 3000;
                fill: #fff;
                // background: $brown;
                border-radius: 10px;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.6));

                .zoom-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    padding: 2% 5%;

                    p {
                        color: #fff;
                        text-transform: capitalize;
                        margin: 0;
                        font-size: 20px;
                    }
                }

                .close {
                    // position: absolute;
                    // top: 2%;
                    // right: 2%;
                    width: 30px;
                    height: 30px;
                    fill: #fff;
                    z-index: 3500;
                    cursor: pointer;
                }
            }

        }

    }

    .cp-text {
        // margin-top: 5%;
        padding: 0 5% 5% 5%;

        p {
            font-size: 18px;
            line-height: 200%;
        }

        b {
            font-size: 25px;
        }

        img {
            width: 40%;
            margin: auto;
            display: block;
        }

        .sizechart {
            display: flex;
            justify-content: center;

            img {
                width: calc(100% / 3);
                margin: 1%;
                display: block;
                object-fit: contain;
            }
        }
    }

    .cp-how {
        padding: 0 5% 5% 5%;

        h3 {
            @include title;
            font-size: 40px;
        }

        p {
            font-size: 18px;
            line-height: 200%;
            margin-top: 2%;
        }
    }

    .cp-order {
        h3 {
            @include title;
            font-size: 40px;
            text-align: center;
        }

        .cp-order-text {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
            background: $skin;
            margin-bottom: 5%;

            .cp-order-body {
                width: calc(100%/2.2);
                display: flex;
                align-items: center;
                padding: 5%;

                &:nth-child(1),
                &:nth-child(2) {
                    border-bottom: 2px solid $golden;
                }

                &:nth-child(1),
                &:nth-child(3) {
                    border-right: 2px solid $golden;
                }

                .cp-order-text-icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 30%;

                    svg,
                    img {
                        width: 70px;
                        height: 70px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: bold;
                        margin-top: 5%;
                    }
                }

                .cp-order-text-text {
                    width: 70%;
                    text-align: center;

                    h4 {
                        font-size: 25px;
                        font-weight: bold;
                        margin-bottom: 2%;
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }

        p {
            font-size: 18px;
            padding: 0 5% 5% 5%;
        }
    }



}

.frames {
    padding: 5% 5% 0 5%;
    margin-top: 10%;

    .frames-body {
        // margin-top: -25%;
        margin-bottom: 5%;

        .stretched-slider {
            // margin-top: 5%;
            padding: 5%;

            .frame3-slider-card {
                width: 100%;
                box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 1.0);
                position: relative;
                display: flex;
                flex-direction: column;
                border: 1px solid rgba($color: #fff, $alpha: 0.6);

                img {
                    width: 120%;
                    border: 2px solid #fff;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
                }

            }
        }

        .slider2 {
            .react-responsive-3d-carousel__carousel__item>* {
                object-fit: contain !important;
            }

            .react-responsive-3d-carousel__carousel__item {
                width: 400px !important;
                height: 400px !important;
                padding: 10% 0 !important;
            }

            .react-responsive-3d-carousel__carousel {
                height: 370px !important;
            }
        }

        .stretched-content {
            // margin-top: -25%;
            font-size: 18px;

            ul {
                li {
                    list-style-type: unset;
                }
            }
        }
    }
}

.shadow {
    box-shadow: none !important;
}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .customized_painting {
        margin-top: 20%;

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }
            }
        }

        .cp-text {
            p {
                font-size: 16px;
            }

            b {
                font-size: 18px;
            }

            img {
                width: 100%;
            }

            .sizechart {
                flex-direction: column;

                img {
                    width: 100%;
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }

        .cp-order {
            h3 {
                font-size: 30px;
            }

            .cp-order-text {
                flex-direction: column;

                .cp-order-body {
                    width: 100%;
                    border-bottom: 2px solid $golden !important;
                    height: 30vh;

                    // &:nth-child(1),
                    // &:nth-child(2),
                    // &:nth-child(3) {
                    //     // border: none !important;
                    // }

                    .cp-order-text-text {
                        h4 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .frames {
        .frames-body {
            .slider2 {
                .react-responsive-3d-carousel__carousel__item>* {
                    background: #fff;
                    padding: 0 10%;
                }
            }
        }

    }

    .react-responsive-3d-carousel__arrows.right {
        right: -5%;
    }

    .react-responsive-3d-carousel__arrows.left {
        left: -5%;
    }
}

@media (min-width: 481px) and (max-width: $md) {
    .customized_painting {
        margin-top: 20%;

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }
            }
        }

        .cp-text {
            p {
                font-size: 16px;
            }

            b {
                font-size: 18px;
            }

            img {
                width: 100%;
            }


            .sizechart {
                flex-direction: column;

                img {
                    width: 100%;
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }

        .cp-order {
            h3 {
                font-size: 30px;
            }

            .cp-order-text {
                flex-direction: column;

                .cp-order-body {
                    width: 100%;
                    border-bottom: 2px solid $golden !important;
                    height: 30vh;

                    // &:nth-child(1),
                    // &:nth-child(2),
                    // &:nth-child(3) {
                    //     // border: none !important;
                    // }

                    .cp-order-text-text {
                        h4 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .frames {
        .frames-body {
            .slider2 {
                .react-responsive-3d-carousel__carousel__item>* {
                    background: #fff;
                    padding: 0 10%;
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .customized_painting {
        margin-top: 10%;

        .cp-slider {
            flex-direction: column;

            .cp-slider-images,
            .cp-slider-text {
                width: 100%;
            }

            .cp-slider-text {
                margin-top: 5%;

                h3 {
                    font-size: 30px;
                }
            }
        }

        .cp-text {
            p {
                font-size: 16px;
            }

            b {
                font-size: 18px;
            }

            img {
                width: 60%;
            }


            .sizechart {
                img {
                    width: calc(100% / 2);
                }
            }
        }

        .cp-how {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }
        }

        .cp-order {
            h3 {
                font-size: 30px;
            }

            .cp-order-text {
                flex-direction: column;

                .cp-order-body {
                    width: 100%;
                    border-bottom: 2px solid $golden !important;
                    height: 30vh;

                    // &:nth-child(1),
                    // &:nth-child(2),
                    // &:nth-child(3) {
                    //     // border: none !important;
                    // }

                    .cp-order-text-text {
                        h4 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .frames {
        .frames-body {
            .slider2 {
                .react-responsive-3d-carousel__carousel__item>* {
                    background: #fff;
                    padding: 0 10%;
                }
            }
        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}