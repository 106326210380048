@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';


// Header  ========================

.header {
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3000;

    .header-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5% 2%;
        background: rgb(244, 231, 215);
        background: linear-gradient(189deg, rgba(244, 231, 215, 1) 0%, rgba(52, 44, 39, 1) 100%);
        border-bottom: 1px solid $brown;

        p {
            width: 100%;
            font-size: 16px;
            letter-spacing: 1px;
            text-align: center;
            font-weight: bold;
            color: #fff;
            margin: 0;
        }
    }

    .header-links {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1% 5%;
        background: rgb(244, 231, 215);
        background: linear-gradient(189deg, rgba(244, 231, 215, 1) 0%, rgba(255, 250, 248, 1) 100%);
        position: relative;

        a {
            margin-right: 5%;
            // font-weight: bold;
            font-size: 20px;
            text-transform: capitalize;
        }

        .links1 {
            width: 30%;
            display: flex;
            align-items: center;
        }



        .header-logo {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .logoimg {
                width: 12%;
            }

            .logotext {
                width: 40%;
            }
        }

        .links2 {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .pro-link {

        width: 100%;
        // height: 250%;
        position: absolute;
        z-index: 1000;
        left: 0%;
        top: 100%;
        background: $brownGradient;
        padding: 30px;
        color: #fff;
        display: none;
        // flex-direction: column;
        justify-content: center;
        animation: slide-in-up .5s 1;

        .close {
            position: absolute;
            top: 5%;
            right: 5%;
            width: 20px !important;
            height: 20px !important;
            z-index: 4000;

            path {
                fill: #fff;
            }

        }

        .link1,
        .link2,
        .link3 {
            margin-right: 5%;

            .link-body {
                display: flex;

                .links {
                    width: calc(100% / 2);
                    display: flex;
                    flex-direction: column;
                }
            }


            h3,
            .last-link {
                font-size: 24px;
                color: #fff;
                font-weight: bold;
                // border-bottom: 1px solid #fff;
                margin-bottom: 18px;
            }

            a {
                font-size: 18px;
                margin-bottom: 2%;
            }
        }

        .link1 {
            width: 40%;
        }

        .link2,
        .link3 {
            width: 20%;

            .link-body {
                .links {
                    width: 100%;
                }
            }
        }

    }

    .header-mobile {
        display: none;
        padding: 1% 5%;
        background: rgb(244, 231, 215);
        background: linear-gradient(189deg, rgba(244, 231, 215, 1) 0%, rgba(255, 250, 248, 1) 100%);
        align-items: center;

        .header-logo {
            width: 40%;
            display: flex;
            align-items: center;

            .logoimg {
                width: 25%;
            }

            .logotext {
                width: 80%;
            }
        }

        .menu {
            fill: $brown;
            width: 30px;
            height: 30px;
            margin-left: auto;
        }

        .links1 {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background: $skin;
            padding: 5%;

            .first {
                margin-top: 30%;
            }

            a {
                font-size: 20px;
                text-transform: capitalize;
                margin-top: 10%;
            }

            .close {
                fill: $brown;
                width: 30px;
                height: 30px;
                margin-left: auto;
            }
        }
    }
}

// Header ends ========================

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .header {
        .header-links {
            display: none;
        }

        .header-mobile {
            display: flex;
        }

        .pro-link {
            z-index: 4000;
            top: 40%;
            height: 60%;
            flex-direction: column;

            .link1,
            .link2,
            .link3 {
                width: 100%;

                .link-body {
                    .links {
                        a {
                            font-size: 12px;
                            margin-top: 0;
                        }
                    }
                }


                h3,
                .last-link {
                    font-size: 18px;
                    margin-bottom: 5px;
                    margin-top: 4%;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) {
    .header {
        .header-links {
            display: none;
        }

        .header-mobile {
            display: flex;
        }

        .pro-link {
            z-index: 4000;
            top: 40%;
            height: 60%;
            flex-direction: column;

            .link1,
            .link2,
            .link3 {
                width: 100%;

                .link-body {
                    .links {
                        a {
                            font-size: 12px;
                            margin-top: 0;
                        }
                    }
                }


                h3,
                .last-link {
                    font-size: 18px;
                    margin-bottom: 5px;
                    margin-top: 4%;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}