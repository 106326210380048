@import "../Sass/variables.scss";
@import "../Sass/mixin.scss";
@import "../Sass/animations.scss";

.login {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../Images/16.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    width: 100%;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
        margin: 5% auto;

        .logoimg {
            width: 70%;
        }

        .logotext {
            width: 170%;
        }

        img {
            width: 100%;
        }
    }

    .form-2-wrapper {
        background: $goldenGradient;
        padding: 50px;
        border-radius: 8px;
    }

    input.form-control {
        padding: 11px 20px;
        border: none;
        border: 2px solid #fff;
        border-radius: 30px;
        background-color: transparent;
        font-family: Arial, Helvetica, sans-serif;

        &::placeholder {
            color: $brown;
        }
    }

    input.form-control:focus {
        box-shadow: none !important;
        outline: 0px !important;
        background-color: transparent;
    }

    button.login-btn {
        background: $brown;
        color: #fff;
        border: none;
        padding: 10px;
        border-radius: 30px;
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
    }

    .register-test a {
        color: #000;
    }

    .social-login button {
        border-radius: 30px;
    }
}

.admin-header {
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    top: 0;
    padding: 0 5%;
    height: 20%;
    background: #fff;
    display: flex;
    align-items: center;

    .btn {
        margin-left: auto;
        display: flex;
        align-items: center;
        width: 30%;
    }

    button {
        background: $goldenGradient;
        color: #000;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 3%;
        font-weight: bold;
        margin-right: 2%;
    }

    .logo {
        background: transparent;
        padding: 0;
    }

}

.dashboard-app {
    width: 40%;
    margin: auto;

    h3 {
        font-weight: bold;

    }

    .admin-settings {
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 2%;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid $golden;
        }

        button {
            margin-right: 0;
        }
    }

}

.admin {
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        margin: 5%;

        .logoimg {
            width: 20%;
        }

        .logotext {
            width: 80%;
        }

        img {
            width: 100%;
        }
    }

    button,
    a {
        background: $goldenGradient;
        color: #000;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 3%;
        font-weight: bold;
        margin-right: 5%;
    }

    .responses {
        padding: 0 5%;
        margin-top: 15%;

        h3 {
            font-size: 30px;
            color: $brown;
            font-weight: bold;
            margin-bottom: 3%;
        }



        table {
            width: 100%;

            thead {
                tr {
                    border-bottom: 1px solid $brown;
                    text-align: center;
                    font-size: 20px;
                }
            }

            tbody {
                tr {
                    td {
                        border-right: 1px solid #fff;
                        border-bottom: 1px solid #fff;
                        padding: 0 10px;
                        font-size: 16px;
                        color: #fff;
                    }

                    &:nth-child(odd) {
                        background: $brown;
                    }
                }
            }
        }
    }
}

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .admin-header {
        height: 15%;
        padding: 5%;

        .btn {
            // margin-left: 5%;
            width: 40%;
            flex-direction: column;

            a,
            button {
                font-size: 14px;
                padding: 2px 10px;
                width: 100%;
            }
        }
    }

    .admin {
        .logo {
            .logoimg {
                width: 40%;
            }

            .logotext {
                width: 100%;
            }
        }


        .responses {
            margin-top: 30%;

            h3 {
                font-size: 20px;
            }

            .dashboard-app {
                width: 80%;
            }

            table {
                thead {
                    tr {
                        font-size: 16px;
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 481px) and (max-width: $md) {
    .admin-header {
        height: 15%;
        padding: 5%;

        .btn {
            // margin-left: 5%;
            width: 40%;
            flex-direction: column;

            a,
            button {
                font-size: 14px;
                padding: 2px 10px;
                width: 100%;
            }
        }
    }

    .admin {
        .logo {
            .logoimg {
                width: 40%;
            }

            .logotext {
                width: 100%;
            }
        }


        .responses {
            margin-top: 30%;

            h3 {
                font-size: 20px;
            }

            .dashboard-app {
                width: 80%;
            }

            table {
                thead {
                    tr {
                        font-size: 16px;
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
    .admin-header {
        height: 15%;
        padding: 5%;

        .btn {
            // margin-left: 5%;
            width: 45%;
        }
    }

    .admin {
        .responses {
            margin-top: 20%;


            .dashboard-app {
                width: 80%;
            }

        }

    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}