@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
// padding

// font-family
$font: "Nunito Sans", sans-serif;

$boxShadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

// colors

$cream: #FFFAF8;
$skin: #F4E7D7;
$golden: #B29B60;
$brown: #342C27;

// gradients
$goldenGradient: linear-gradient(169deg, rgba(244, 231, 215, 1) 0%, rgba(178, 155, 96, 1) 100%);
$brownGradient: linear-gradient(169deg, rgba(52, 44, 39, 1) 0%, rgba(52, 44, 39, 1) 100%);