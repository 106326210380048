@mixin flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

@mixin centerPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin title {
    font-size: 25px;
    text-align: left;
    color: $brown;
    background: -webkit-linear-gradient($brown, $golden);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-transform: capitalize;
    // text-align: center;
    margin-bottom: 3%;
}

@mixin button {
    background: rgb(244, 231, 215);
    background: linear-gradient(169deg, rgba(244, 231, 215, 1) 0%, rgba(178, 155, 96, 1) 100%);
    text-transform: capitalize;
    padding: 1% 5%;
    border-radius: 40px;
    box-shadow: inset $boxShadow;
    margin-top: 5%;
    display: block;
    text-align: center;
    width: 30%;
    font-weight: bold;
    font-size: 20px;

    &:hover {
        background: rgb(52, 44, 39);
        background: linear-gradient(355deg, rgba(52, 44, 39, 1) 0%, rgba(92, 81, 75, 1) 63%);
        color: #fff;
        transition: .5s all ease;
        transform: translateX(10px) scale(1.1);
    }
}