@import "../../Sass/variables.scss";


.whatsapp {
  position: fixed;
  bottom: 5%;
  right: 2%;
  z-index: 3000;
  // background: $skin;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.6);
  // padding: .5%;
  border: 1px solid #fff;

  img {
    width: 100%;
    border-radius: 10px;
  }
}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
  .whatsapp {
    width: 50px;
    height: 50px;
    right: 5%;
  }
}

@media (min-width: 481px) and (max-width: $md){
  .whatsapp {
    right: 5%;
  }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}