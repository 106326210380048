@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';

.policy {
    margin-top: 7%;
    padding: 5%;

    h2 {
        font-size: 30px;
        font-weight: bold;
        color: $brown;
        margin-bottom: 2%;
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 3%;
    }

    ol {

        li {
            margin-bottom: 2%;
            font-weight: bold;
            list-style-type: unset;
            font-size: 20px;

        }
    }

    p {
        font-size: 16px;

        a {
            font-size: 18px;
            color: $brown;
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 16px;
            font-weight: normal;
            list-style-type: disc;
            margin-bottom: .5%;

            b {
                margin-right: 1%;
            }

            &:first-child {
                margin-top: 2%;
            }
        }
    }

    .return {
        p {
            margin-top: 2%;
        }

        li {
            font-weight: normal;
            font-size: 16px;
        }
    }


}

// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
    .policy {
        margin-top: 15%;

        h2 {
            font-size: 25px;
        }
    }
}

@media (min-width: 481px) and (max-width: $md) {}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}