@import '../../Sass/variables.scss';
@import '../../Sass/mixin.scss';
@import '../../Sass/animations.scss';


// Footer =============================

.frame1 {
    margin-top: 8%;

    img {
        width: 100%;
        object-fit: cover;
    }

    .frame1-text {
        @include centerPosition;
        left: 25%;
        top: 60%;
        width: 40%;

        h3 {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 5%;
        }

        p {
            font-size: 18px;
        }

        a {
            @include button;
        }
    }
}

.frame2 {
    padding: 2% 5%;

    p {
        text-align: center;
        font-size: 18px;
    }

    .about {
        display: flex;
        margin-top: 5%;

        .about-img {
            margin-right: 3%;
            width: 50%;
            margin-bottom: 1%;
            position: relative;

            // float: left;

            img {
                width: 100%;
                box-shadow: $boxShadow;
                border-radius: 10px;
                border: 4px solid $skin;
            }
        }

        .about-text {
            width: 60%;
            padding: 0 2%;
            // position: relative;
            // z-index: 1;
            // margin-left: 2%;

            h4 {
                font-size: 40px;
                margin: 0;
                // background: rgb(178, 155, 96);
                // background: linear-gradient(90deg, rgba(178, 155, 96, 1) 0%, rgba(244, 231, 215, 0) 100%);
                padding: 0 0 3% 15%;
                font-weight: bold;
                margin-left: -15%;
                margin-bottom: 0;
                color: #000;
                text-shadow: 1px 1px 1px rgba($color: #fff, $alpha: 1.0);
                position: relative;
                text-align: center;

                .shapes {
                    position: absolute;
                    top: 30%;
                    left: 10%;
                    // background: #000;
                    width: 95%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .line {
                        width: 100%;
                        height: 1px;
                        // position: absolute;
                        // top: 100%;
                        // left: 13%;
                        background: rgba($color: $golden, $alpha: 0.7);
                        z-index: -1;
                    }

                    .circle1 {
                        width: 20px;
                        height: 20px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }

                    .circle2 {
                        width: 10px;
                        height: 10px;
                        margin-left: 5px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }
                }

            }


            .para {
                position: relative;
                margin-top: 2%;

                p {
                    font-size: 16px;
                    text-align: left;
                    line-height: 160%;
                    // padding-left: 5%;

                    // margin-left: 10%;
                }

                .shapes {
                    position: absolute;
                    top: 115%;
                    left: -4%;
                    // background: #000;
                    width: 110%;
                    // height: 100%;
                    display: flex;
                    align-items: center;

                    .line {
                        width: 100%;
                        height: 1px;
                        // position: absolute;
                        // top: 100%;
                        // left: 13%;
                        background: rgba($color: $golden, $alpha: 0.7);
                        z-index: -1;
                    }

                    .circle1 {
                        width: 20px;
                        height: 20px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }

                    .circle2 {
                        width: 10px;
                        height: 10px;
                        margin-right: 5px;
                        // position: absolute;
                        // top: 90%;
                        left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }
                }
            }


        }
    }
}

.frame3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 5%;
    // margin-top: -10%;
    // margin-bottom: -5%;
    width: 100%;

    &::after {
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
        background: rgb(244, 231, 215);
        background: linear-gradient(90deg, rgba(244, 231, 215, 1) 0%, rgba(251, 243, 236, 1) 63%, rgba(244, 231, 215, 1) 100%);
        content: "";
        position: absolute;
        width: 100%;
        height: 60%;
        top: 15%;
        left: 0;
        z-index: -1;
    }

    .frame3-text {
        width: 40%;
        margin-right: 5%;
        text-align: center;
        margin-top: -5%;

        h3 {
            font-weight: bold;
            font-size: 40px;
            line-height: 150%;
            margin-bottom: 5%;
        }

        a {
            background: rgb(52, 44, 39);
            background: linear-gradient(90deg, rgba(52, 44, 39, 1) 0%, rgba(178, 155, 96, 1) 63%);
            text-transform: capitalize;
            padding: 1% 5%;
            border-radius: 40px;
            box-shadow: $boxShadow;
            display: block;
            text-align: center;
            width: 40%;
            font-weight: bold;
            color: #fff;
            margin: auto;
            display: block;
            font-size: 20px;

            &:hover {
                background: rgb(52, 44, 39);
                background: linear-gradient(355deg, rgba(52, 44, 39, 1) 0%, rgba(92, 81, 75, 1) 63%);
                color: #fff;
                transition: .5s all ease;
                transform: scale(1.1);
            }
        }

    }

    h5 {
        position: absolute;
        background: rgb(244, 231, 215);
        background: linear-gradient(90deg, rgba(244, 231, 215, 1) 0%, rgba(251, 243, 236, 1) 63%, rgba(244, 231, 215, 1) 100%);
        padding: 0.5% 5% 0.5% 10%;
        top: 80%;
        width: 100%;
        left: 0;
        z-index: -1;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
        font-size: 18px;
    }
}

.frame3-slider {
    background: rgba($color: #fff, $alpha: 0.1);
    width: 60%;
    overflow: hidden;
    margin: auto;

    .react-responsive-3d-carousel__carousel__item>* {
        object-fit: contain !important;
        box-shadow: none !important;
    }

    .react-responsive-3d-carousel__carousel__item {
        width: 280px !important;
        height: 350px !important;
        padding: 10% 0 !important;
    }

    .react-responsive-3d-carousel__carousel {
        height: 350px !important;
        // width: 600px !important;
        box-shadow: none !important;
    }

    .frame3-slider-body {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        .alice-carousel__dots,
        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: none;
        }

        .alice-carousel__stage-item {
            margin-right: -0.6%;
        }

        .__target+li+li {
            margin-left: 10%;
        }

        .__target+li {
            transform: translateX(8px) scale(1.4);
            transition: .5s all ease;
            z-index: 3000;


            .frame3-slider-card {
                box-shadow: 2px 2px 55px rgba($color: #000, $alpha: 1);
            }
        }

        li {
            padding: 35% 2%;
        }

        .frame3-slider-card {
            width: 100%;
            box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 1.0);
            position: relative;
            display: flex;
            flex-direction: column;
            border: 1px solid rgba($color: #fff, $alpha: 0.6);

            img {
                width: 150%;
                border: 2px solid #fff;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
            }

        }
    }
}

.frame3-mobile {
    display: none;
}

.frame4 {
    padding: 0 5%;
    margin-bottom: 5%;

    .last {
        font-size: 20px;
        text-align: center;

    }

    .frame4-body {
        margin: 5% 0;
        display: flex;
        // align-items: center;

        .frame4-img {
            width: 50%;
            margin-right: 3%;
            // margin-bottom: 1%;
            // float: left;

            img {
                width: 100%;
                box-shadow: 5px 5px 5px rgba($color: #000, $alpha: 0.8);
                border-radius: 10px;
            }
        }

        .frame4-text {
            width: 60%;
            padding: 0 2%;
            // position: relative;
            // z-index: 1;
            // margin-left: 2%;

            h3 {
                @include title;
                line-height: 200%;
                margin-bottom: 1%;
                font-size: 25px;
            }

            h5 {
                font-size: 22px;
                margin-bottom: 2%;
                font-style: italic;
            }

            h4 {
                font-size: 40px;
                margin: 0;
                // background: rgb(178, 155, 96);
                // background: linear-gradient(90deg, rgba(178, 155, 96, 1) 0%, rgba(244, 231, 215, 0) 100%);
                padding: 0 0 3% 15%;
                font-weight: bold;
                margin-left: -15%;
                margin-bottom: 2%;
                color: #000;
                text-shadow: 1px 1px 1px rgba($color: #fff, $alpha: 1.0);
                position: relative;
                text-align: center;


                .shapes {
                    position: absolute;
                    top: 70%;
                    left: 10%;
                    // background: #000;
                    width: 95%;
                    // height: 100%;
                    display: flex;
                    align-items: center;

                    .line {
                        width: 90%;
                        height: 1px;
                        // position: absolute;
                        // top: 100%;
                        // left: 13%;
                        background: rgba($color: $golden, $alpha: 0.7);
                        z-index: -1;
                    }

                    .circle1 {
                        width: 20px;
                        height: 20px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }

                    .circle2 {
                        width: 10px;
                        height: 10px;
                        margin-left: 5px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }
                }

            }

            .para {
                position: relative;
                padding: 0 5%;

                p {
                    font-size: 18px;
                    text-align: left;
                    line-height: 160%;
                    // margin-left: 10%;
                    padding: 5% 0;
                }

                .shapes {
                    position: absolute;
                    top: 60%;
                    left: -4%;
                    // background: #000;
                    width: 105%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .line {
                        width: 100%;
                        height: 1px;
                        // position: absolute;
                        // top: 100%;
                        // left: 13%;
                        background: rgba($color: $golden, $alpha: 0.7);
                        z-index: -1;
                    }

                    .circle1 {
                        width: 20px;
                        height: 20px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }

                    .circle2 {
                        width: 10px;
                        height: 10px;
                        margin-right: 5px;
                        // position: absolute;
                        // top: 90%;
                        // left: 33%;
                        background: $goldenGradient;
                        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.frame5 {
    padding: 0% 5% 0 5%;

    .frame5-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        .frame5-card {
            width: calc(100% / 4.4);
            position: relative;
            margin: 1%;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 10px 10px 20px rgba($color: #000, $alpha: 0.5);

            &:hover {
                img {
                    transform: scale(1.2);
                    transition: .5s all ease;
                }

                .frame5-text {
                    display: none;
                }

                a {
                    display: flex;
                    transition: .5s all ease;
                }
            }

            img {
                width: 100%;
                border-radius: 10px;

                border: 2px solid $golden;
            }

            .frame5-text {
                @include centerPosition;
                /* From https://css.glass */
                background: rgba(178, 155, 96, 0.73);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid $golden;
                padding: 5%;
                width: 90%;
                top: 95%;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.5);

                h3 {
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                    font-size: 23px;
                    text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                    margin-bottom: 10%;
                }

            }


            a {
                @include centerPosition;
                background: rgba(52, 44, 39, 0.7);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                width: 70%;
                height: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 25px;
                text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                color: #fff;
                text-align: center;
                animation: zoom-in .5s 1;
                display: none;
                border: 1px solid #fff;
                box-shadow: inset 2px 2px 15px rgba($color: #fff, $alpha: 0.5);
            }
        }
    }

    .frame5-last {
        margin: 5% 0;
        position: relative;

        img {
            width: 100%;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
        }

        h6 {
            @include centerPosition;
            left: 25%;
            width: 40%;
            font-size: 20px;
            line-height: 180%;
            // font-weight: bold;
            text-shadow: 1px 1px 1px rgba($color: #fff, $alpha: 1);
        }
    }

    .last {
        font-size: 30px;
        text-align: center;
    }
}

.frame6 {
    padding: 0 5% 0 5%;

    .frame6-body {
        margin: 5% 0;

        .frame6-img {
            position: relative;

            img {
                width: 100%;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
                border-radius: 10px;
                border: 5px solid $golden;
            }

            h4 {
                font-size: 35px;
                text-align: left;
                color: $brown;
                background: -webkit-linear-gradient($brown, $golden);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
                text-transform: capitalize;
                text-align: center;
                margin-bottom: 5%;
            }

            p {
                margin: 3% 0;
                font-size: 18px;
            }
        }

        .frame6-services {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;

            .frame6-card {
                width: calc(100% / 4.4);
                position: relative;
                margin: 1%;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);

                &:hover {
                    img {
                        transform: scale(1.2);
                        transition: .5s all ease;
                    }

                    .frame6-text {
                        display: none;
                    }

                    a {
                        display: flex;
                        transition: .5s all ease;
                    }
                }

                img {
                    width: 100%;
                    border-radius: 10px;

                    border: 2px solid $golden;
                }

                .frame6-text {
                    @include centerPosition;
                    /* From https://css.glass */
                    background: rgba(178, 155, 96, 0.73);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid $golden;
                    padding: 5%;
                    width: 90%;
                    top: 95%;
                    box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.5);

                    h3 {
                        text-align: center;
                        color: #fff;
                        font-weight: bold;
                        font-size: 20px;
                        text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                        margin-bottom: 10%;
                    }

                }


                a {
                    @include centerPosition;
                    background: rgba(52, 44, 39, 0.7);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    width: 70%;
                    height: 30%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    font-size: 25px;
                    text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.8);
                    color: #fff;
                    text-align: center;
                    animation: zoom-in .5s 1;
                    display: none;
                    border: 1px solid #fff;
                    box-shadow: inset 2px 2px 15px rgba($color: #fff, $alpha: 0.5);
                }
            }
        }
    }
}

.frame7 {
    padding: 0 5% 0 5%;

    .title {
        h4 {
            .shapes {
                width: 100%;
            }
        }
    }

    p {
        font-size: 18px;
        text-align: left;
    }

    .frame7-body {
        display: flex;
        justify-content: space-between;
        margin-top: 5%;
        // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
        padding: 2% 5%;

        .alice-carousel__stage-item {
            width: 100% !important;
            background: transparent !important;

            img {
                width: 100% !important;
            }
        }

        .alice-carousel__dots {
            margin-top: -5%;
            z-index: 2;
            position: relative;
        }

        .alice-carousel__dots-item {
            background: #fff;
            border: 1px solid #fff;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
            width: 10px;
            height: 10px;
        }

        .__active {
            background: $goldenGradient;
        }

        .frame7-text {
            width: 40%;
            margin-right: 5%;
            position: relative;


            h3 {
                font-size: 35px;
                text-align: left;
                margin-left: -10%;
                color: $brown;
                background: -webkit-linear-gradient($brown, $golden);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
                margin-bottom: 10%;
            }

            h4 {
                // position: absolute;
                // left: 0;
                // top: 25%;
                font-weight: bold;
                font-size: 35px;
                line-height: 150%;
                margin-left: -10%;
                margin-top: 5%;
            }

            p {
                // position: absolute;
                // left: 0;
                // top: 55%;
                width: 110%;
                text-align: left;
                font-size: 17px;
                line-height: 180%;
                margin-left: -12%;
                margin-top: 5%;
            }
        }

        .frame7-image {
            width: 60%;

            .alice-carousel__prev-btn {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(-50%, -50%);
                background: #fff;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                border-radius: 50%;

                svg {
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    transform: translate(-50%, -50%);
                    width: 50px;
                    height: 50px;
                    transform: scaleX(-1);
                    fill: $golden;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                    border-radius: 50%;
                }
            }

            .alice-carousel__next-btn {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                right: -7%;
                transform: translate(-50%, -50%);
                background: #fff;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                border-radius: 50%;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50px;
                    height: 50px;
                    fill: $golden;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                    border-radius: 50%;
                }
            }

            img {
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
                border: 5px solid $golden;
                border-radius: 30px;
            }
        }
    }

    .frame7-order {
        display: flex;
        margin-bottom: 5%;
        position: relative;
        z-index: 1;
        display: block;
        border: 5px solid $golden;
        border-radius: 10px;

        img {
            width: 100%;

            // &:first-child {
            //     transform: scaleX(-1);
            // }
        }

        // .rectangle {
        //     position: absolute;
        //     top: 0;
        //     width: 50%;
        //     left: 0;
        //     height: 100%;
        //     background: rgba($color: #fff, $alpha: 0.7);
        //     z-index: 2;
        // }

        a {
            background: $goldenGradient;
            padding: 1% 5%;
            font-size: 25px;
            text-shadow: 1px 1px 1px rgba($color: #fff, $alpha: 0.8);
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: 5%;
            border-radius: 40px;
            font-weight: bold;
            z-index: 4;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);

            &:hover {
                background: rgb(52, 44, 39);
                background: linear-gradient(355deg, rgba(52, 44, 39, 1) 0%, rgba(92, 81, 75, 1) 63%);
                color: #fff;
                transition: .5s all ease;
                transform: translateX(-120px) scale(1.1) translateY(-20px);
                border: 1px solid #fff;
            }
        }
    }

    .frame7-usp {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 5% 0;
        padding: 0 5%;

        .frame7-usp-card {
            width: calc(100%/2.2);
            margin: 1%;
            display: flex;
            align-items: center;

            svg {
                width: 60px;
                height: 60px;
                fill: $golden;
                filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
                margin-right: 5%;
            }

            h3 {
                width: 70%;
                background: $skin;
                font-size: 22px;
                padding: 3% 10%;
                border-radius: 40px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);


            }
        }
    }
}

.frame8 {
    padding: 5% 5% 0 5%;
    display: flex;

    .form {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-right: 5%;

        h3 {
            font-size: 30px;
            text-transform: capitalize;
            font-weight: bold;
        }

        p {
            margin-bottom: 5%;
        }

        .input {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                width: calc(100% / 2);

                &:first-child {
                    margin-right: 5%;
                }
            }
        }

        input,
        textarea {
            font-size: 20px;
            outline: none;
            padding: 2% 0;
            margin-bottom: 1%;
            border: none;
            border-bottom: 2px solid $golden;
            color: $golden;

            &::placeholder {
                text-transform: capitalize;
            }
        }

        textarea {
            height: 25vh;
        }

        button {
            background: $goldenGradient;
            padding: 1% 2%;
            border-radius: 10px;
            border: none;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
            width: 25%;
            font-size: 18px;
            font-weight: bold;

            &:hover {
                background: rgb(52, 44, 39);
                background: linear-gradient(355deg, rgba(52, 44, 39, 1) 0%, rgba(92, 81, 75, 1) 63%);
                color: #fff;
                transition: .5s all ease;
            }
        }
    }

    .frame8-info {
        width: 30%;
        background: rgba($color: $skin, $alpha: 0.4);
        padding: 5% 5%;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);

        .frame8-info-card {
            display: flex;
            flex-direction: column;
            margin-bottom: 5%;

            svg {
                width: 50px;
                height: 50px;
                fill: $golden;
                filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
            }

            h4 {
                font-size: 25px;
                font-weight: 800;
                color: $golden;
                margin-top: 5%;
            }
        }
    }
}

.contact-page {
    margin-top: 10%;
    margin-bottom: 5%;
}

.frame9 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: $skin, $alpha: 0.4);
    padding: 1% 0;

    p {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .frame9-star {
        display: flex;
        margin-left: 5%;

        svg {
            width: 40px;
            height: 40px;
            fill: $golden;
        }
    }
}

// Footer ends ========================



// Media Queries=====================================================

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .frame1 {
        margin-top: 15%;
        height: 50vh;

        img {
            height: 100%;
        }

        .frame1-text {
            width: 70%;
            top: 35%;
            left: 40%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
                line-height: 120%;
            }

            a {
                width: 60%;
                font-size: 14px;
                line-height: 120%;
                padding: 3% 5%;
            }
        }
    }

    .frame2 {
        p {
            font-size: 14px;
        }

        .about {
            flex-direction: column;

            .about-img,
            .about-text {
                width: 100%;
            }

            .about-text {
                margin-top: 5%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    .shapes {
                        top: 105%;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame3 {
        display: none;
    }

    .frame3-mobile {
        display: block;
        padding: 5% 0;
        margin-top: 5%;

        .frame3-slider {
            width: 100%;
            padding: 0 5%;
        }

        .frame3-text {
            padding: 5%;
            background: $skin;
        }

        a {
            background: rgb(52, 44, 39);
            background: linear-gradient(90deg, rgba(52, 44, 39, 1) 0%, rgba(178, 155, 96, 1) 63%);
            text-transform: capitalize;
            padding: 1% 5%;
            border-radius: 40px;
            box-shadow: $boxShadow;
            display: block;
            text-align: center;
            width: 50%;
            font-weight: bold;
            color: #fff !important;
            margin: auto;
            display: block;
            font-size: 20px;
            margin-top: 5%;
        }

        h3 {
            font-weight: bold;
            text-align: center;
            font-size: 20px;
        }

        h5 {
            background: $skin;
            text-align: center;
            padding: 3%;
            font-size: 16px;
        }

        .react-responsive-3d-carousel__carousel__item>* {
            object-fit: contain !important;
        }

        .react-responsive-3d-carousel__carousel__item {
            width: 200px !important;
            height: 250px !important;
            padding: 10% 0 !important;
        }

        .react-responsive-3d-carousel__carousel {
            height: 250px !important;
        }
    }

    .frame4 {
        .frame4-body {
            flex-direction: column;

            .frame4-img,
            .frame4-text {
                width: 100%;
            }

            .frame4-text {
                margin-top: 5%;

                h4 {
                    font-size: 25px;
                }

                .para {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame5 {
        .frame5-body {
            margin-top: 5%;

            .frame5-card {
                width: calc(100% / 2.1);

                &:hover {
                    a {
                        font-size: 16px;
                        border-radius: 5px;
                    }
                }

                .frame5-text {
                    height: 50%;

                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }

        .frame5-last {

            h6 {
                font-size: 16px;
                width: 100%;
                padding: 2%;
                // line-height: 120%;
                position: unset;
                transform: none;
            }
        }
    }

    .frame6 {
        .frame6-body {
            .frame6-img {
                h4 {
                    font-size: 20px;
                    margin-top: 10%;
                }

                p {
                    font-size: 16px;
                }
            }

            .frame6-services {
                .frame6-card {
                    width: calc(100% / 2.1);

                    &:hover {
                        a {
                            font-size: 16px;
                            border-radius: 5px;
                        }
                    }

                    .frame6-text {
                        h3 {
                            font-size: 16px;
                        }

                        top: 90%;
                    }
                }
            }
        }
    }

    .frame7 {
        .frame7-body {
            flex-direction: column;
            padding: 0;

            .alice-carousel__dots {
                margin-top: -10%;
            }

            .frame7-text {
                width: 100%;
                margin: 0;

                p {
                    font-size: 16px;
                    width: 100%;
                    margin: 0;
                }
            }

            .frame7-image {
                width: 100%;

                img {
                    border-radius: 20px;
                }

                .alice-carousel__next-btn,
                .alice-carousel__prev-btn {

                    width: 30px;
                    height: 30px;

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .frame7-order {
            margin-top: 5%;

            a {
                font-size: 16px;
            }
        }

        .frame7-usp {
            flex-direction: column;

            .frame7-usp-card {
                width: 100%;

                svg {
                    width: 40px;
                    height: 40px;
                }

                h3 {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 16px;
        }
    }

    .frame8 {
        flex-direction: column;

        .form,
        .frame8-info {
            width: 100%;
        }

        .form {
            margin-bottom: 5%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }


            input,
            textarea {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            button {
                width: 50%;
                font-size: 16px;
            }
        }

        .frame8-info {
            .frame8-info-card {
                svg {
                    width: 30px;
                    height: 30px;
                }

                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .contact-page {
        margin-top: 25%;
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .frame1 {
        margin-top: 15%;
        height: 50vh;

        img {
            height: 100%;
        }

        .frame1-text {
            width: 70%;
            top: 40%;
            left: 40%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
                line-height: 120%;
            }

            a {
                width: 60%;
                font-size: 16px;
                line-height: 120%;
                padding: 3% 5%;
            }
        }
    }

    .frame2 {
        p {
            font-size: 16px;
        }

        .about {
            flex-direction: column;

            .about-img,
            .about-text {
                width: 100%;
            }

            .about-text {
                margin-top: 5%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    .shapes {
                        top: 105%;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame3 {
        display: none;
    }

    .frame3-mobile {
        padding: 5% 0;
        margin-top: 5%;

        .frame3-slider {
            width: 100%;
            padding: 0 5%;
        }

        .frame3-text {
            padding: 5%;
            background: $skin;
        }

        a {
            background: rgb(52, 44, 39);
            background: linear-gradient(90deg, rgba(52, 44, 39, 1) 0%, rgba(178, 155, 96, 1) 63%);
            text-transform: capitalize;
            padding: 1% 5%;
            border-radius: 40px;
            box-shadow: $boxShadow;
            display: block;
            text-align: center;
            width: 40%;
            font-weight: bold;
            color: #fff !important;
            margin: auto;
            display: block;
            font-size: 20px;
            margin-top: 5%;
        }

        h3 {
            font-weight: bold;
            text-align: center;
            font-size: 30px;
        }

        h5 {
            background: $skin;
            text-align: center;
            padding: 3%;
            font-size: 16px;
        }

        .react-responsive-3d-carousel__carousel__item>* {
            object-fit: contain !important;
        }

        .react-responsive-3d-carousel__carousel__item {
            width: 250px !important;
            height: 300px !important;
            padding: 10% 0 !important;
        }

        .react-responsive-3d-carousel__carousel {
            height: 300px !important;
        }
    }

    .frame4 {
        .frame4-body {
            flex-direction: column;

            .frame4-img,
            .frame4-text {
                width: 100%;
            }

            .frame4-text {
                margin-top: 5%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame5 {
        .frame5-body {
            margin-top: 5%;

            .frame5-card {
                width: calc(100% / 2.1);

                &:hover {
                    a {
                        font-size: 16px;
                        border-radius: 5px;
                    }
                }

                .frame5-text {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }

        .frame5-last {

            h6 {
                font-size: 12px;
                width: 50%;
                padding: 2%;
                line-height: 120%;
            }
        }
    }

    .frame6 {
        .frame6-body {
            .frame6-img {
                h4 {
                    font-size: 25px;
                    margin-top: 10%;
                }

                p {
                    font-size: 16px;
                }
            }

            .frame6-services {
                .frame6-card {
                    width: calc(100% / 2.1);

                    &:hover {
                        a {
                            font-size: 16px;
                            border-radius: 5px;
                        }
                    }

                    .frame6-text {
                        h3 {
                            font-size: 18px;
                        }

                        top: 90%;
                    }
                }
            }
        }
    }

    .frame7 {
        .frame7-body {
            flex-direction: column;
            padding: 0;

            .frame7-text {
                width: 100%;
                margin: 0;

                p {
                    font-size: 16px;
                    width: 100%;
                    margin: 0;
                }
            }

            .frame7-image {
                width: 100%;
            }
        }

        .frame7-order {
            margin-top: 5%;

            a {
                font-size: 16px;
            }
        }

        .frame7-usp {
            flex-direction: column;

            .frame7-usp-card {
                width: 100%;

                svg {
                    width: 40px;
                    height: 40px;
                }

                h3 {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 16px;
        }
    }

    .frame8 {
        flex-direction: column;

        .form,
        .frame8-info {
            width: 100%;
        }

        .form {
            margin-bottom: 5%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }


            input,
            textarea {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            button {
                width: 50%;
                font-size: 16px;
            }
        }

        .frame8-info {
            .frame8-info-card {

                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .contact-page {
        margin-top: 25%;
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .frame1 {
        margin-top: 15%;
        height: 50vh;

        img {
            height: 100%;
        }

        .frame1-text {
            width: 70%;
            top: 37%;
            left: 40%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
                line-height: 120%;
            }

            a {
                width: 60%;
                font-size: 16px;
                line-height: 120%;
                padding: 3% 5%;
            }
        }
    }


    .frame2 {
        p {
            font-size: 14px;
        }

        .about {
            flex-direction: column;

            .about-img,
            .about-text {
                width: 100%;
            }

            .about-text {
                margin-top: 5%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    .shapes {
                        top: 105%;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame3 {
        display: none;
    }

    .frame3-mobile {
        display: block;
        padding: 5% 0;
        margin-top: 5%;

        .frame3-slider {
            width: 100%;
            padding: 0 5%;
        }

        .frame3-text {
            padding: 5%;
            background: $skin;
        }

        a {
            background: rgb(52, 44, 39);
            background: linear-gradient(90deg, rgba(52, 44, 39, 1) 0%, rgba(178, 155, 96, 1) 63%);
            text-transform: capitalize;
            padding: 1% 5%;
            border-radius: 40px;
            box-shadow: $boxShadow;
            display: block;
            text-align: center;
            width: 40%;
            font-weight: bold;
            color: #fff !important;
            margin: auto;
            display: block;
            font-size: 20px;
            margin-top: 5%;
        }

        h3 {
            font-weight: bold;
            text-align: center;
            font-size: 30px;
        }

        h5 {
            background: $skin;
            text-align: center;
            padding: 3%;
            font-size: 16px;
        }

        .react-responsive-3d-carousel__carousel__item>* {
            object-fit: contain !important;
        }

        .react-responsive-3d-carousel__carousel__item {
            width: 200px !important;
            height: 250px !important;
            padding: 10% 0 !important;
        }

        .react-responsive-3d-carousel__carousel {
            height: 250px !important;
        }
    }

    .frame4 {
        .frame4-body {
            flex-direction: column;

            .frame4-img,
            .frame4-text {
                width: 100%;
            }

            .frame4-text {
                margin-top: 5%;

                .para {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame5 {
        .frame5-body {
            margin-top: 5%;

            .frame5-card {
                width: calc(100% / 2.1);

                &:hover {
                    a {
                        font-size: 16px;
                        border-radius: 5px;
                    }
                }

                .frame5-text {
                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }

        .frame5-last {

            h6 {
                font-size: 8px;
                width: 50%;
                padding: 2%;
                line-height: 120%;
            }
        }
    }

    .frame6 {
        .frame6-body {
            .frame6-img {
                h4 {
                    font-size: 20px;
                    margin-top: 10%;
                }

                p {
                    font-size: 16px;
                }
            }

            .frame6-services {
                .frame6-card {
                    width: calc(100% / 2.1);

                    &:hover {
                        a {
                            font-size: 16px;
                            border-radius: 5px;
                        }
                    }

                    .frame6-text {
                        h3 {
                            font-size: 16px;
                        }

                        top: 90%;
                    }
                }
            }
        }
    }

    .frame7 {
        .frame7-body {
            flex-direction: column;
            padding: 0;

            .frame7-text {
                width: 100%;
                margin: 0;

                p {
                    font-size: 16px;
                    width: 100%;
                    margin: 0;
                }
            }

            .frame7-image {
                width: 100%;

                .alice-carousel__next-btn,
                .alice-carousel__prev-btn {

                    width: 30px;
                    height: 30px;

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .frame7-order {
            margin-top: 5%;

            a {
                font-size: 16px;
            }
        }

        .frame7-usp {
            flex-direction: column;

            .frame7-usp-card {
                width: 100%;

                svg {
                    width: 40px;
                    height: 40px;
                }

                h3 {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 16px;
        }
    }

    .frame8 {
        flex-direction: column;

        .form,
        .frame8-info {
            width: 100%;
        }

        .form {
            margin-bottom: 5%;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }


            input,
            textarea {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            button {
                width: 50%;
                font-size: 16px;
            }
        }

        .frame8-info {
            .frame8-info-card {
                svg {
                    width: 30px;
                    height: 30px;
                }

                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .contact-page {
        margin-top: 25%;
    }
}

@media (min-width: 768px) and (max-width: $lg) {
    .frame1 {
        // margin-top: 10%;

        .frame1-text {
            width: 50%;
            top: 40%;
            left: 30%;

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
                // line-height: 120%;
            }

            a {
                width: 40%;
                font-size: 20px;
                line-height: 120%;
            }
        }
    }

    .frame2 {
        p {
            font-size: 16px;
        }

        .about {
            flex-direction: column;

            // .about-img,
            // .about-text {
            //     width: 100%;
            // }

            .about-img {
                width: 80%;
                display: block;
                margin: auto;
            }

            .about-text {
                margin-top: 5%;
                width: 100%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    .shapes {
                        top: 105%;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .frame3 {

        .frame3-text {
            h3 {
                font-size: 25px;
            }

            a {
                width: 50%;
            }
        }


    }

    .frame3-slider {
        // width: 50%;
        // margin-right: 10%;
        // overflow: visible;

        .frame3-slider-body {
            .frame3-slider-card {
                img {
                    width: 70%;
                }
            }
        }

    }

    .frame3-mobile {
        padding: 5% 0;
        margin-top: 5%;
        display: none;

        .frame3-slider {
            width: 100%;
            padding: 0 5%;
        }

        .frame3-text {
            padding: 5%;
            background: $skin;
        }

        a {
            background: rgb(52, 44, 39);
            background: linear-gradient(90deg, rgba(52, 44, 39, 1) 0%, rgba(178, 155, 96, 1) 63%);
            text-transform: capitalize;
            padding: 1% 5%;
            border-radius: 40px;
            box-shadow: $boxShadow;
            display: block;
            text-align: center;
            width: 40%;
            font-weight: bold;
            color: #fff !important;
            margin: auto;
            display: block;
            font-size: 20px;
            margin-top: 5%;
        }

        h3 {
            font-weight: bold;
            text-align: center;
            font-size: 30px;
        }

        h5 {
            background: $skin;
            text-align: center;
            padding: 3%;
            font-size: 16px;
        }

        .react-responsive-3d-carousel__carousel__item>* {
            object-fit: contain !important;
        }

        .react-responsive-3d-carousel__carousel__item {
            width: 200px !important;
            height: 250px !important;
            padding: 10% 0 !important;
        }

        .react-responsive-3d-carousel__carousel {
            height: 250px !important;
        }
    }

    .frame4 {
        .frame4-body {

            .frame4-text {
                margin-top: -2%;

                h4 {
                    font-size: 30px;
                }

                .para {
                    p {
                        font-size: 16px;
                        line-height: 140%;
                    }
                }
            }
        }
    }

    .frame5 {
        .frame5-body {
            margin-top: 5%;

            .frame5-card {
                width: calc(100% / 3.2);

                &:hover {
                    a {
                        font-size: 16px;
                        border-radius: 5px;
                    }
                }

                .frame5-text {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }

        .frame5-last {

            h6 {
                font-size: 16px;
                width: 50%;
                padding: 2%;
                line-height: 140%;
            }
        }
    }

    .frame6 {
        .frame6-body {
            .frame6-img {

                p {
                    font-size: 16px;
                }
            }

            .frame6-services {
                .frame6-card {
                    &:hover {
                        a {
                            font-size: 16px;
                            border-radius: 5px;
                        }
                    }

                    .frame6-text {
                        h3 {
                            font-size: 16px;
                        }

                        height: 80px;
                        top: 90%;
                    }
                }
            }
        }
    }

    .frame7 {
        .frame7-body {
            flex-direction: column;
            padding: 0;

            .frame7-text {
                width: 100%;
                margin: 0;

                p {
                    font-size: 16px;
                    width: 100%;
                    margin: 0;
                }
            }

            .frame7-image {
                width: 100%;
            }
        }

        .frame7-order {
            margin-top: 5%;

            a {
                font-size: 16px;
            }
        }

        .frame7-usp {
            // flex-direction: column;

            .frame7-usp-card {
                // width: 100%;

                svg {
                    width: 40px;
                    height: 40px;
                }

                h3 {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 16px;
        }
    }

    .frame8 {

        .form {

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }


            input,
            textarea {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            button {
                width: 50%;
                font-size: 18px;
                margin-top: 5%;
            }
        }

        .frame8-info {
            width: 40%;

            .frame8-info-card {
                svg {
                    width: 30px;
                    height: 30px;
                }

                h4 {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {

    .frame1 .frame1-text {

        a {
            width: 50%;
        }
    }

}